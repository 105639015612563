import React from 'react'
import { NextPage } from 'next'
import Head from 'next/head'
import LoginRoute from '../../../../routes/Login'
import { useIntl } from 'react-intl'

const Login: NextPage = () => {
  const intl = useIntl()
  return (
    <>
      <Head>
        <title>
          {intl.formatMessage({ id: 'SIGN_IN', defaultMessage: 'Sign In' })}
        </title>
      </Head>
      <LoginRoute />
    </>
  )
}

export default Login
