import { CartType } from '@src/routes'
import React from 'react'
import { useUserSession } from './useUserSession'
import { CartRequestAction } from '@src/types/graphql-types'
import { useChinaUser } from '@utils/useChinaUser'

export enum CompanyNames {
  MilliporeSigma = 'MilliporeSigma',
  Merck = 'Merck',
}
export enum CompanyDomains {
  MilliporeSigma = 'emdmillipore.com',
  Merck = 'merckmillipore.com',
}

export const MS_COUNTRIES = ['US', 'CA', 'PR', 'VI', 'GU', 'AS', 'MP', 'UM']

// define isMSCountry helper
export const isMSCountry = (country) => MS_COUNTRIES.includes(country)

// Cache and return appropriate company name based on users country.
export const useCompanyName = (): CompanyNames => {
  const { userSession } = useUserSession()
  return React.useMemo(() => {
    return isMSCountry(userSession.country)
      ? CompanyNames.MilliporeSigma
      : CompanyNames.Merck
  }, [userSession.country])
}

//checks if the domain name ends with o
export const isDomainIO = (hostName: string): boolean =>
  (hostName && hostName.slice(-1) === 'o') || false

export enum SplitCartCompanyNames {
  EMDMillipore = 'EMD Millipore',
  MerckChemicals = 'Merck Chemicals',
  Marketplace = 'Marketplace',
}

export const useSplitCartCompanyName = (
  cartType: CartType | CartRequestAction
) => {
  const baseCase = useCompanyName()
  const isChinaUser = useChinaUser()
  switch (cartType) {
    case CartType.BlueCart:
    case CartType.BlueSavedCart:
      return isChinaUser ? SplitCartCompanyNames.MerckChemicals : baseCase
    case CartType.MarketplaceCart:
      return SplitCartCompanyNames.Marketplace
    case CartRequestAction.Marketplace:
      return SplitCartCompanyNames.Marketplace
    default:
      return baseCase
  }
}

export const b2BSupportEmails: Record<CompanyNames, string> = {
  [CompanyNames.MilliporeSigma]: 'ecommerce@milliporesigma.com',
  [CompanyNames.Merck]: 'b2bsupport@merckgroup.com',
}
