import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import SimpleHeader from './SimpleHeader'
import SpacingBox from '@src/components/SpacingBox'
import SitewideMessages from '@src/components/SitewideMessages'

const useStyles = makeStyles({
  root: { height: '100%' },
  body: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '0',
  },
})

export enum LayoutSpacingOptions {
  NONE = 'none',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

const LAYOUT_SPACING_VALUES = {
  [LayoutSpacingOptions.NONE]: 0,
  [LayoutSpacingOptions.SM]: {
    xs: 2,
    md: 4,
  },
  [LayoutSpacingOptions.MD]: {
    xs: 4,
    md: 9,
  },
  [LayoutSpacingOptions.LG]: {
    xs: 12,
    md: 16,
  },
}

interface SimpleLayoutProps {
  className?: string
  spacing?: LayoutSpacingOptions
  children: ReactNode
  hideLink?: boolean
  fixedHeader?: boolean
  hideLinkAndSiteWideMessage?: boolean
}

const SimpleLayout: React.FC<SimpleLayoutProps> = ({
  children,
  spacing = LayoutSpacingOptions.NONE,
  className,
  hideLink = false,
  fixedHeader = false,
  hideLinkAndSiteWideMessage = false,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {!hideLinkAndSiteWideMessage && <SitewideMessages />}
      <SimpleHeader
        hideLink={hideLink || hideLinkAndSiteWideMessage}
        fixedHeader={fixedHeader}
      />
      <SpacingBox pt={LAYOUT_SPACING_VALUES[spacing]} pb={10}>
        {(spacingProps) => (
          <div
            {...spacingProps}
            className={clsx(classes.body, className, spacingProps.className)}
          >
            {children}
          </div>
        )}
      </SpacingBox>
    </div>
  )
}

export default SimpleLayout
