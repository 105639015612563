import Icon, { BaseIconProps } from './Icon'
function SuccessCircleIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 20 20"
        {...props}
      >
        <path d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0m5.29 8.85-5.13 4.86c-.23.22-.54.35-.86.35-.34 0-.66-.14-.9-.38L5.75 11c-.49-.49-.49-1.3 0-1.79s1.3-.49 1.79 0l1.79 1.84 4.24-4c.5-.47 1.3-.45 1.77.05s.45 1.3-.05 1.77z" />
      </svg>
    </Icon>
  )
}
export default SuccessCircleIcon
