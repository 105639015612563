import React, { PropsWithChildren } from 'react'
import { isObject } from 'lodash'
import NextLink, { LinkProps } from 'next/link'
import { useUserSession } from '@utils/useUserSession'

type CustomLinkProps = PropsWithChildren<LinkProps>

export const Link = (props: CustomLinkProps) => {
  const { userSession } = useUserSession()
  const language = userSession.language?.toLowerCase() || 'en'
  const country = userSession.country?.toUpperCase() || 'US'

  const isAsObj = isObject(props.as)

  // @ts-ignore
  const as = isAsObj ? props.as.pathname : props.as
  const isHrefObj = isObject(props.href)
  const href = isObject(props.href) ? props.href.pathname || '' : props.href

  // Ignore routes that already have basename OR catchAll
  if (
    href.startsWith(`/${country}/${language}`) ||
    href.startsWith('/[country]/[language]') ||
    href.startsWith('/[...rest]') ||
    href.startsWith('http')
  ) {
    return <NextLink {...props} prefetch={false} legacyBehavior />
  }

  const hrefPath = `/[country]/[language]${href}`
  // If the provided 'href' is a string, pass it through as a string so we do not mangle query params
  const hrefWithBasename = isHrefObj
    ? {
        pathname: hrefPath,
        // @ts-ignore
        query: props.href.query,
      }
    : hrefPath

  const asPath = `/${country}/${language}${as?.toLowerCase() || href}`
  // If the provided 'as' is a string, pass it through as a string so we do not mangle query params
  const asWithBasename = isAsObj
    ? {
        pathname: asPath,
        // @ts-ignore
        query: props.as?.query,
      }
    : asPath

  return (
    <NextLink
      {...props}
      as={asWithBasename}
      href={hrefWithBasename}
      prefetch={false}
      legacyBehavior
    />
  )
}
