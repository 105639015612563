import Icon, { BaseIconProps } from './Icon'
function WarningCircleIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 20 18"
        {...props}
      >
        <path d="M19.76 14.87 11.85 1.03A2.07 2.07 0 0 0 10.01 0c-.75-.02-1.45.38-1.83 1.03L.27 14.87c-.36.65-.36 1.44 0 2.09.37.65 1.06 1.05 1.81 1.03h15.8c1.18-.01 2.13-.98 2.12-2.16 0-.33-.09-.66-.24-.96m-9.75.79c-.79 0-1.42-.64-1.42-1.43s.64-1.42 1.43-1.42a1.425 1.425 0 0 1-.01 2.85m1.43-5.7a1.43 1.43 0 1 1-2.86 0V5.69a1.43 1.43 0 1 1 2.86 0z" />
      </svg>
    </Icon>
  )
}
export default WarningCircleIcon
