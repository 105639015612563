import React, { ReactNode, CSSProperties } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel'
import HoverToolTip from '../HoverToolTip/HoverTooltipForForms/HoverTooltipForForms'
import InfoIcon from '@material-ui/icons/Info'

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    margin: theme.spacing(0, 0, 1),
    display: 'block',
  },
  labelSmall: {
    fontSize: theme.typography.pxToRem(12),
  },
  labelLarge: {
    fontSize: theme.typography.pxToRem(14),
    margin: theme.spacing(0, 0, 3, 1),
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  infoIcon: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
  },
}))

interface LiquidFormLabelProps {
  size?: 'small' | 'medium' | 'large'
  children: ReactNode
  required?: boolean
  className?: string
  style?: CSSProperties
  htmlFor?: string
  error?: boolean
  toolTipText?: ReactNode
}

const LiquidFormLabel: React.FC<LiquidFormLabelProps> = ({
  size = 'medium',
  children,
  required,
  className,
  style,
  htmlFor,
  error,
  toolTipText,
  ...other
}) => {
  const classes = useStyles()

  return (
    <FormLabel
      classes={{
        root: clsx(
          classes.label,
          error && classes.error,
          size === 'small' && classes.labelSmall,
          size === 'large' && classes.labelLarge
        ),
      }}
      className={className}
      style={style}
      htmlFor={htmlFor}
      {...other}
    >
      {required ? '* ' : null}
      {children}
      {toolTipText && (
        <HoverToolTip toolTipText={toolTipText}>
          <InfoIcon className={classes.infoIcon} />
        </HoverToolTip>
      )}
    </FormLabel>
  )
}

export default LiquidFormLabel
