import React from 'react'
import isMarkup from '@utils/isMarkup'
import DOMPurify from 'isomorphic-dompurify'

interface HandleMarkupProps {
  value?: string | null
  className?: string | ''
  id?: string | ''
  dataScrub?: boolean
  title?: string | ''
}

// when DomPurify handles an attribute
DOMPurify.addHook('uponSanitizeAttribute', function (node, ev) {
  // if element has target="_blank", set security attrs
  if (ev.attrName === 'target' && ev.attrValue === '_blank') {
    const modifiedNode = node as Element
    modifiedNode.setAttribute('rel', 'noreferrer noopener')
  }
})

export const HandleMarkup: React.FC<HandleMarkupProps> = ({
  value,
  className,
  id,
  dataScrub,
  title,
}) => {
  return value ? (
    isMarkup(value) ? (
      <>
        <span
          title={title}
          className={className}
          id={id}
          data-scrub={dataScrub}
          // ONLY TO BE DISABLED IF USING a) HandleMarkup or b) in a script tag
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            // Replace end of string HTML entity with closing angle bracket
            // before sanitizing
            __html: DOMPurify.sanitize(value.replace(/&gt;$/, '>'), {
              ADD_ATTR: ['target'],
            }),
          }}
        />
      </>
    ) : (
      <span title={title} className={className} id={id} data-scrub={dataScrub}>
        {value}
      </span>
    )
  ) : null
}

export default HandleMarkup
