import React, { ReactNode } from 'react'
import {
  Tooltip as TooltipMui,
  Theme,
  ClickAwayListener,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.white,
    '&:before': {
      border: `solid ${theme.palette.grey[500]} 1px`,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(2, 4),
    borderRadius: '5px',
    boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.16)',
    whitespace: 'nowrap',
    top: theme.spacing(-1),
    width: '300px',
    border: `solid ${theme.palette.grey[500]} 1px`,
    '&.MuiTooltip-tooltipPlacementTop': {
      margin: theme.spacing(0),
    },
  },
  tooltipMobile: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(4),
    borderRadius: '5px',
    boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.16)',
    whitespace: 'nowrap',
    top: theme.spacing(2),
  },
}))

interface HoverTooltipProps {
  children: JSX.Element
  toolTipText: NonNullable<ReactNode>
  delay?: number
}

const HoverToolTip: React.FC<HoverTooltipProps> = ({
  children,
  toolTipText,
  delay,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm')) === true
  const [open, setOpen] = React.useState<boolean>(false)

  return (
    <>
      {isDesktop && (
        <TooltipMui
          arrow
          interactive
          title={toolTipText}
          placement="top"
          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
          leaveDelay={delay || 800}
        >
          {children}
        </TooltipMui>
      )}

      {!isDesktop && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <span>
            <TooltipMui
              title={toolTipText}
              placement="top"
              classes={{ arrow: classes.arrow, tooltip: classes.tooltipMobile }}
              onClose={() => setOpen(false)}
              open={open}
              arrow
              interactive
              disableFocusListener
              leaveDelay={800}
            >
              {children}
            </TooltipMui>
          </span>
        </ClickAwayListener>
      )}
    </>
  )
}

export default HoverToolTip
