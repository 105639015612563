import { theme } from '@src/styles/theme'

/**
 * Use to generate VR styles for any variant of spacing. Should be used
 * to generate uncommon VR values.
 *
 * Can be extended to support by-breakpoint variations.
 */
export const getVerticalRhythm = (variant: number) => {
  return { marginBottom: theme.spacing(variant) }
}

// Static representation of common VerticalRhythm values. Should represent the most often used values
// in the application to avoid generation in each component.
const vrStyles = {
  vr1: getVerticalRhythm(1),
  vr2: getVerticalRhythm(2),
  vr3: getVerticalRhythm(3),
  vr4: getVerticalRhythm(4),
  vr6: getVerticalRhythm(6),
  vr8: getVerticalRhythm(8),
  vr10: getVerticalRhythm(10),
}

export default vrStyles
