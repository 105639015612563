import React from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Alert, { AlertProps } from '@material-ui/lab/Alert'
import ErrorCircleIcon from '@src/icons/ErrorCircleIcon'
import InfoCircleIcon from '@src/icons/InfoCircleIcon'
import SuccessCircleIcon from '@src/icons/SuccessCircleIcon'
import WarningCircleIcon from '@src/icons/WarningCircleIcon'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme: Theme) => {
  return {
    '@global': {
      '.MuiAlert-outlinedInfo': {
        border: `1px solid ${theme.palette.primary.main}`,
        '& .MuiAlert-icon': {
          color: `${theme.palette.primary.main}`,
        },
      },
    },
    root: {
      borderWidth: '1px 1px 1px 5px',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      display: 'flex',
      background: theme.palette.common.white,
    },
    message: {
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightMedium,
      padding: 0,
      width: '100%',
    },
    icon: {
      padding: 0,
      paddingTop: theme.spacing(1),
      '& svg': {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    action: {
      alignItems: 'flex-start',
      marginRight: theme.spacing(1),
    },
    iconButtonRoot: {
      color: theme.palette.common.black,
      fontSize: theme.typography.pxToRem(16),
      marginTop: theme.spacing(0.5),
      padding: 0,
      '& svg': {
        fontSize: theme.typography.pxToRem(24),
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    actionItems: {
      display: 'flex',
      alignItems: 'center',
    },
    actionItem: {
      display: 'flex',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      borderRight: `solid 1px ${theme.palette.grey[400]}`,
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        borderRight: 'none',

        paddingRight: 0,
      },
    },
    '& .actionItem + .actionItem': {
      paddingLeft: theme.spacing(3),
    },
  }
})

const GlobalAlert: React.FC<AlertProps> = ({
  severity = 'info',
  children,
  action,
  onClose,
  ...otherProps
}) => {
  const classes = useStyles()
  const actions: React.ReactNode[] = []
  const IconComponent = {
    error: ErrorCircleIcon,
    warning: WarningCircleIcon,
    info: InfoCircleIcon,
    success: SuccessCircleIcon,
  }[severity]

  if (action) {
    actions.push(action)
  }

  if (onClose) {
    actions.push(
      <IconButton
        color="inherit"
        classes={{ root: classes.iconButtonRoot }}
        onClick={onClose}
        data-testid="alert-x"
      >
        <CloseIcon />
      </IconButton>
    )
  }

  const actionItems = (
    <div className={classes.actionItems}>
      {actions.map((action, index) => (
        <div className={classes.actionItem} key={`action-${index}`}>
          {action}
        </div>
      ))}
    </div>
  )

  return (
    <Alert
      classes={{
        root: classes.root,
        message: classes.message,
        icon: classes.icon,
        action: classes.action,
      }}
      icon={<IconComponent />}
      severity={severity}
      variant="outlined"
      action={actionItems}
      {...otherProps}
    >
      {children}
    </Alert>
  )
}

export default GlobalAlert
