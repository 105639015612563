import Icon, { BaseIconProps } from './Icon'
function ErrorCircleIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 20 20"
        {...props}
      >
        <path d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0m0 16.67c-.92 0-1.67-.75-1.67-1.67s.75-1.67 1.67-1.67 1.67.75 1.67 1.67-.75 1.67-1.67 1.67M11.67 10c0 .92-.75 1.67-1.67 1.67S8.33 10.92 8.33 10V5c0-.92.75-1.67 1.67-1.67s1.67.75 1.67 1.67z" />
      </svg>
    </Icon>
  )
}
export default ErrorCircleIcon
