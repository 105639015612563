import { getBrandIdentity } from '@src/utils/getBrandIdentity'
import { OurBrands } from '@sial/common-design'
import { CompanyNames } from '@utils/useCompanyName'
import { isMSCountry } from '@src/utils/useCompanyName'

const LOGO_PATHS = {
  [OurBrands.SigmaAldrich]: {
    [CompanyNames.MilliporeSigma]: '/static/logos/purple/millipore_sigma.svg',
    [CompanyNames.Merck]: '/static/logos/purple/merck.svg',
  },
  [OurBrands.MerckMillipore]: {
    [CompanyNames.MilliporeSigma]: '/static/logos/blue/millipore_sigma.svg',
    [CompanyNames.Merck]: '/static/logos/blue/merck.svg',
  },
}

export const getLogoByBrandAndRegion = (companyKey: CompanyNames): string => {
  const brandIdentity = getBrandIdentity()

  const brandGroup = OurBrands[brandIdentity]
  const companyName = CompanyNames[companyKey]

  return LOGO_PATHS[brandGroup][companyName]
}

export const PreloadCompanyLogo = ({ country }) => {
  const showAsMsCountry = isMSCountry(country)
    ? CompanyNames.MilliporeSigma
    : CompanyNames.Merck
  const logoPath = getLogoByBrandAndRegion(showAsMsCountry)

  return (
    <link
      rel="preload"
      as="image"
      href={logoPath}
      data-testid="header-logo-preload"
    />
  )
}
