import React, { useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { useRouter } from '@src/routes'
import { sendSessionErrorEvent } from '@utils/analytics'
import SimpleLayout, {
  LayoutSpacingOptions,
} from '@src/components/SimpleLayout'
import LoginMktContent from './LoginMktContent'
import LoginForm from './LoginForm'
import {
  SnackbarMessages,
  useGlobalSnackbar,
} from '@src/components/GlobalSnackbar/globalSnackBarContext'
import messages from '@utils/messages'
import { FormattedMessage, useIntl } from 'react-intl'
import GlobalAlert from '@src/components/GlobalAlert'
import { Chat, ChatChannel } from '@src/components/Chat'

const Login = () => {
  const router = useRouter()
  const { formatMessage } = useIntl()
  const { setSnackbar } = useGlobalSnackbar()
  const { sessionExpired = false } = router.query ? router.query : {}
  const isThirdPartyUser = router.query?.authn_t === 'oauth2' ? true : false
  // DTAQ accept invite token
  const [tempToken] =
    router.query.redirect?.match(/tempaccesstoken([^&]*)/) ?? []
  const countryCode = router.query.country
  const isDtaqCountryWithToken = Boolean(countryCode === 'JP' && tempToken)
  const hasTokenToAcceptAccess =
    isDtaqCountryWithToken && tempToken ? tempToken.split('=')[1] : false
  const useStyles = makeStyles((theme: Theme) => ({
    alertContainer: {
      margin: theme.spacing(4),
      maxWidth: theme.spacing(150),
    },
    root: {
      alignItems: 'center',
    },
    message: {
      color: theme.palette.common.white,
    },
    mktContent: {
      display: isThirdPartyUser ? 'hidden' : 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
        marginLeft: theme.spacing(12),
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(5),
      },
    },
    loginSnackbar: {
      top: 24 + 60,
    },
  }))

  const classes = useStyles()
  const [globalError, setGlobalError] = useState('')

  React.useEffect(() => {
    if (sessionExpired && !hasTokenToAcceptAccess) {
      setSnackbar(SnackbarMessages.UserNotAuthorizedOrTokenExpired)
      sendSessionErrorEvent(true)
    }
  }, [sessionExpired])

  // Display third party error
  const handleThirdPartyError = () => {
    setGlobalError(messages.ERROR_THIRD_PARTY_OAUTH2.id)
  }

  // Handle third party redirect error
  React.useEffect(() => {
    if (isThirdPartyUser && !!router?.query?.error) {
      handleThirdPartyError()
    }
  }, [])

  return (
    <SimpleLayout spacing={LayoutSpacingOptions.MD}>
      <Container maxWidth="lg">
        {!isThirdPartyUser ? (
          <Grid container spacing={0}>
            <Grid item xs={12} md={5}>
              <LoginForm
                isThirdPartyUser={isThirdPartyUser}
                handleThirdPartyError={handleThirdPartyError}
              />
            </Grid>
            <Grid item xs={false} md={6} className={classes.mktContent}>
              <LoginMktContent />
            </Grid>
          </Grid>
        ) : (
          <Box display="flex" alignItems="center" flexDirection="column">
            {globalError && (
              <Grid item xs={12}>
                <div className={classes.alertContainer}>
                  <GlobalAlert
                    severity="error"
                    variant="filled"
                    classes={{ root: classes.root, message: classes.message }}
                    onClose={() => setGlobalError('')}
                  >
                    <FormattedMessage {...messages[globalError]} />
                  </GlobalAlert>
                </div>
              </Grid>
            )}
            <LoginForm
              isThirdPartyUser={isThirdPartyUser}
              handleThirdPartyError={handleThirdPartyError}
            />
          </Box>
        )}
        <Chat
          text={formatMessage({
            id: 'COMMERCE_CHAT_CUSTOMER_CARE',
            defaultMessage: 'Chat with Customer Care',
          })}
          channel={ChatChannel.CustomerService}
        />
      </Container>
    </SimpleLayout>
  )
}

export default Login
