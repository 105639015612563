import React from 'react'
import { SvgIconProps, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useCompanyName } from '@utils/useCompanyName'
import { getLogoByBrandAndRegion } from '@utils/logoResolver'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'block',
    '@media (max-width: 959px)': {
      maxWidth: '100px',
    },
  },
  brandLogoSize: {
    width: '100px',
    [theme.breakpoints.up('md')]: {
      width: '154px',
    },
  },
}))

interface CompanyLogoProps extends SvgIconProps {
  msClassName?: string
  rowClassName?: string
}

const CompanyLogo: React.FC<CompanyLogoProps> = () => {
  // Get company name
  const companyName = useCompanyName()
  const classes = useStyles()
  return (
    <img
      className={clsx(classes.root, classes.brandLogoSize)}
      src={getLogoByBrandAndRegion(companyName)}
      alt={companyName}
      data-testid="company-logo"
    />
  )
}

export default CompanyLogo
