import React from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Theme, Typography } from '@material-ui/core'
import messages from '@utils/messages'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: theme.spacing(15.5),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  ul: {
    marginTop: theme.spacing(4),
    paddingLeft: 0,
    listStylePosition: 'inside',
  },
}))

const MESSAGES_LIST = [
  messages.ACCESS_ONLINE_TOOLS,
  messages.CHECK_OUT_FASTER,
  messages.CREATE_AND_SHARE_LISTS,
  messages.MULTIPLE_SHIPPING_LOCATIONS,
  messages.ACCESS_ORDER_HISTORY,
]

const LoginMktContent = () => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title} component="h2" variant="h3">
        <FormattedMessage {...messages.LOGIN_TITLE} />
      </Typography>
      <Typography component="p" variant="body1">
        <FormattedMessage
          {...messages.LOGIN_LEAD}
          values={{
            break: <br />,
          }}
        />
      </Typography>
      <ul className={classes.ul}>
        {MESSAGES_LIST.map((message, index) => (
          <Typography
            key={`login-mkt-msg-${index}`}
            component="li"
            variant="body1"
          >
            <FormattedMessage {...message} />
          </Typography>
        ))}
      </ul>
    </div>
  )
}

export default LoginMktContent
