import Icon, { BaseIconProps } from './Icon'
function InfoCircleIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 20 20"
        {...props}
      >
        <path d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0m1.43 14.75c0 .14-.11.25-.25.25h-1.11c-.83 0-1.5-.67-1.5-1.5V9c0-.14.11-.25.25-.25h1.11c.83 0 1.5.67 1.5 1.5zM10 7.86A1.43 1.43 0 1 1 10 5a1.43 1.43 0 0 1 0 2.86" />
      </svg>
    </Icon>
  )
}
export default InfoCircleIcon
